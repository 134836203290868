import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const username = 'navin@starberry.tv';
const password = 'tU97yq5k2JcKXNAJP132ekkk';

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://wpgraphqlresea.wpengine.com/graphql',
    headers: {
        // Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODE3MjI2NDgsImlzcyI6InRwai1ncmFwaHFsIiwibmJmIjoxNjgxNzIyNjQ4LCJleHAiOjM0MDk3MjI2NDh9.5Bhlsga-rne2SIRFmW1NEyW1Zx8U2UYiXCvSUbGQQqg`
    },
    fetch,
  }),
  cache: new InMemoryCache()
});